import {
  useCallback,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { OrganizationAdminOrderProperty } from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import usePartnerOrganizationsAPI from '../../usePartnerOrganizationsAPI';
import {
  IGetOrganizationAdminsParams,
  TGetOrganizationAdminsMethod,
} from '../../usePartnerOrganizationsAPI/interfaces';
import { IOrganizationAdminsTableRow } from '../../../components/tables/OrganizationAdminsTable';
import { ORGANIZATION_IS_IN_RETENTION_PERIOD_ERROR } from '../../../constants';

export const REACT_QUERY_ORGANIZATION_ADMINS_KEY = [ReactQueryKeyEnum.OrganizationAdmins];

interface IPersistentFilters extends Pick<IGetOrganizationAdminsParams, 'organizationId'> {}

export interface IUseOrganizationAdminsTableParams {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseOrganizationAdminsTableReturn extends
  Omit<IUseTableReturn<IOrganizationAdminsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseQueryTableFetchReturn<TGetOrganizationAdminsMethod>, 'data' | 'isFetchedAfterMount'>
{
  organizationAdmins: IUseQueryTableFetchReturn<TGetOrganizationAdminsMethod>['data'];
  selectedOrganizationAdmins: IUseTableReturn<IOrganizationAdminsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetOrganizationAdminsParams,
    'page' | 'pageLength' | 'organizationId' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<IOrganizationAdminsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetOrganizationAdminsParams['page'];
  perPage: IGetOrganizationAdminsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: OrganizationAdminOrderProperty },
  ),
  columnOrderDirection: true,
};

const useOrganizationAdminsTable = (
  params: IUseOrganizationAdminsTableParams,
): IUseOrganizationAdminsTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getOrganizationAdmins } = usePartnerOrganizationsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback((err: any) => {
    let key = 'common.somethingWentWrong';
    if (err?.response?.data?.includes(ORGANIZATION_IS_IN_RETENTION_PERIOD_ERROR)) {
      key = 'toasts.organizationIsInRetentionPeriod';
    }
    showError({ text: t(key) });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedOrganizationAdmins,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationAdminsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: organizationAdmins, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_ORGANIZATION_ADMINS_KEY,
    queryClient,
    request: getOrganizationAdmins,
    params: {
      page: activePage,
      pageLength: perPage,
      orderPropertyName: OrganizationAdminOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof OrganizationAdminOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    organizationAdmins,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedOrganizationAdmins,
    localization,
    resetActivePage,
    ...restTableProps,
  };
};

export default useOrganizationAdminsTable;
