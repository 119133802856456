import {
  useQuery, useQueryClient, useMutation, type QueryKey,
} from 'react-query';
import {
  resetReactQuery,
  TUseQueryOptions,
  TUseMutationOptions,
  TUseMutationContext,
} from '@uniqkey-frontend/shared-app';
import {
  GetOrganizationByIdResponse,
  NoContentResult,
  UpdatePartnerOrganizationRequest,
  GetOrganizationPortalAccessInfoResponse,
  GetOrganizationSettingsInfoResponse,
} from '@uniqkey-backend-partner/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import usePartnerOrganizationsAPI from '../../usePartnerOrganizationsAPI';
import { REACT_QUERY_ORGANIZATIONS_KEY } from '../../tables/useOrganizationsTable';
import { REACT_QUERY_ORGANIZATIONS_EXTENDED_KEY } from '../../tables/useOrganizationsExtendedTable';
import { REACT_QUERY_GROUP_ORGANIZATIONS_KEY } from '../../tables/useGroupOrganizationsTable';
import {
  REACT_QUERY_GROUP_ORGANIZATIONS_FOR_ADMIN_KEY,
} from '../../tables/useGroupOrganizationsForAdminTable';
import {
  REACT_QUERY_GROUP_LATEST_ORGANIZATIONS_FOR_ADMIN_KEY,
  REACT_QUERY_GROUP_LATEST_ORGANIZATIONS_KEY,
} from '..';

export const REACT_QUERY_ORGANIZATION_KEY = [ReactQueryKeyEnum.Organization];

export const REACT_QUERY_ORGANIZATION_ACCESS_INFO_KEY = [ReactQueryKeyEnum.OrganizationAccessInfo];

export const REACT_QUERY_ORGANIZATION_SETTINGS_INFO_KEY = [
  ReactQueryKeyEnum.OrganizationSettingsInfo,
];

interface IUseGetOrganizationByIdParams {
  organizationId: string;
}

export const useGetOrganizationById = (
  params: IUseGetOrganizationByIdParams,
  options: TUseQueryOptions<GetOrganizationByIdResponse> = {},
) => {
  const { organizationId } = params;
  const { getOrganizationById } = usePartnerOrganizationsAPI();
  return useQuery<GetOrganizationByIdResponse>(
    (REACT_QUERY_ORGANIZATION_KEY as QueryKey[]).concat([organizationId]),
    ({ signal }) => getOrganizationById(organizationId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseUpdateOrganizationParams {
  organizationId: string;
  partnerId: string;
  useOptimisticUpdates?: boolean;
}

export const useUpdateOrganization = (
  params: IUseUpdateOrganizationParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    Omit<UpdatePartnerOrganizationRequest, 'organizationId' | 'partnerId'>,
    TUseMutationContext<GetOrganizationByIdResponse>
  > = {},
) => {
  const { organizationId, partnerId, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateOrganization } = usePartnerOrganizationsAPI();
  const mutationKey = (REACT_QUERY_ORGANIZATION_KEY as QueryKey[]).concat([organizationId]);
  return useMutation(
    mutationKey,
    (organization) => updateOrganization(
      { ...organization, organizationId, partnerId },
    ),
    {
      onMutate: async (newOrganization) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(mutationKey);
        const previousValue = queryClient.getQueryData<
          GetOrganizationByIdResponse
        >(mutationKey);
        queryClient.setQueryData<GetOrganizationByIdResponse>(
          mutationKey,
          (oldOrganization) => ({
            ...oldOrganization,
            ...newOrganization as GetOrganizationByIdResponse,
          }),
        );
        return { previousValue: previousValue as GetOrganizationByIdResponse };
      },
      onError: (err, organization, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GetOrganizationByIdResponse>(
            mutationKey,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(mutationKey);
        resetReactQuery(queryClient, REACT_QUERY_ORGANIZATIONS_KEY);
        resetReactQuery(queryClient, REACT_QUERY_ORGANIZATIONS_EXTENDED_KEY);
        resetReactQuery(queryClient, REACT_QUERY_GROUP_ORGANIZATIONS_KEY);
        resetReactQuery(queryClient, REACT_QUERY_GROUP_ORGANIZATIONS_FOR_ADMIN_KEY);
        resetReactQuery(queryClient, REACT_QUERY_GROUP_LATEST_ORGANIZATIONS_KEY);
        resetReactQuery(queryClient, REACT_QUERY_GROUP_LATEST_ORGANIZATIONS_FOR_ADMIN_KEY);
      },
      ...options,
    },
  );
};

interface IUseGetOrganizationAccessInfoParams {
  organizationId: string;
}

export const useGetOrganizationAccessInfo = (
  params: IUseGetOrganizationAccessInfoParams,
  options: TUseQueryOptions<GetOrganizationPortalAccessInfoResponse> = {},
) => {
  const { organizationId } = params;
  const { getOrganizationAccessInfo } = usePartnerOrganizationsAPI();
  return useQuery<GetOrganizationPortalAccessInfoResponse>(
    (REACT_QUERY_ORGANIZATION_ACCESS_INFO_KEY as QueryKey[]).concat([organizationId]),
    ({ signal }) => getOrganizationAccessInfo(organizationId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseGetOrganizationSettingsInfoParams {
  organizationId: string;
}

export const useGetOrganizationSettingsInfo = (
  params: IUseGetOrganizationSettingsInfoParams,
  options: TUseQueryOptions<GetOrganizationSettingsInfoResponse> = {},
) => {
  const { organizationId } = params;
  const { getOrganizationSettingsInfo } = usePartnerOrganizationsAPI();
  return useQuery<GetOrganizationSettingsInfoResponse>(
    (REACT_QUERY_ORGANIZATION_SETTINGS_INFO_KEY as QueryKey[]).concat([organizationId]),
    ({ signal }) => getOrganizationSettingsInfo(organizationId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
