import { useCallback, useMemo, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnerUsersResponsePartnerUser,
} from '@uniqkey-backend-partner/api-client';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import { USER_ROLES_TRANSLATION_KEYS } from '../../../constants';
import { parseIdParam } from '../../../helpers/parseIdParam';
import { TGetPartnerUsersMethod } from '../../../hooks/usePartnerUsersAPI/interfaces';

export interface IPartnerUsersTableRow extends GetPartnerUsersResponsePartnerUser {}

interface IPartnerUsersTableProps extends
  Omit<IUseTableReturn<IPartnerUsersTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<
    IUseQueryTableFetchReturn<TGetPartnerUsersMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  partnerUsers: IUseQueryTableFetchReturn<TGetPartnerUsersMethod>['data'];
  selectedPartnerUsers: IUseTableReturn<IPartnerUsersTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  options?: IMaterialTableOptions<IPartnerUsersTableRow>;
  partnerId?: string;
}

const PartnerUsersTable = (props: IPartnerUsersTableProps) => {
  const {
    partnerUsers,
    isLoading,
    total,
    localization,
    partnerId,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedPartnerUsers,
    onRowSelectionChange,
    createColumns,
    options,
  } = props;

  const { t } = useTranslations();
  const navigate = useNavigate();

  const { partnerId1, partnerId2, partnerId3 } = useParams();

  const { parsedPartnerId2, parsedPartnerId3 } = useMemo(() => ({
    parsedPartnerId2: parseIdParam(partnerId2!),
    parsedPartnerId3: parseIdParam(partnerId3!),
  }), [partnerId2, partnerId3]);

  const columns = useMemo(() => createColumns([
    {
      title: t('partnerUsersPage.name'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '35%',
    },
    {
      title: t('partnerUsersPage.email'),
      field: 'email',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.email}
        </TypographyWithTooltip>
      ),
      width: '35%',
    },
    {
      title: t('partnerUsersPage.role'),
      field: 'role',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {t(USER_ROLES_TRANSLATION_KEYS[rowData.role as keyof typeof USER_ROLES_TRANSLATION_KEYS])}
        </TypographyWithTooltip>
      ),
      width: '30%',
      sorting: false,
    },
  ]), [createColumns, t]);

  const handlePartnerUserRowClick = useCallback<
    NonNullable<IMaterialTableProps<IPartnerUsersTableRow>['onRowClick']>
  >((event, partnerUser) => {
    const { partnerUserId } = partnerUser!;
    if (partnerId || parsedPartnerId2 || parsedPartnerId3) {
      navigate(`${PageRouteEnum.User}/${
        partnerId1 ?? partnerId
      }/${partnerId2 ?? null}/${partnerId3 ?? null}/${partnerUserId}`);
      return;
    }
    navigate(`${PageRouteEnum.PartnerUsers}/${partnerUserId}`);
  }, [partnerId, partnerId1, partnerId2, partnerId3, parsedPartnerId2, parsedPartnerId3, navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={partnerUsers}
      isLoading={isLoading}
      options={options}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedPartnerUsers}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handlePartnerUserRowClick}
      rowSelectByKey="partnerUserId"
      rowHeight={51}
    />
  );
};

export default memo(PartnerUsersTable);
