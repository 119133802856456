import {
  memo, useMemo, useState, useCallback,
} from 'react';
import {
  getRemainingTimeTo,
  useTranslations,
  WidgetContainer,
  Grid,
  Typography,
  S6,
  S5,
  Tooltip,
  Button,
  formatDate,
  AO4,
  getDatesDiff,
  AR2,
  useSnackbar,
  resetReactQuery,
  TimeSpanEnum,
} from '@uniqkey-frontend/shared-app';
import { useQueryClient } from 'react-query';
import ACLEnum from '../../../../../../enums/ACLEnum';
import { isUniqkey } from '../../../../../../helpers/partnerType';
import { useUser } from '../../../../../../contexts/UserContext';
import { logException } from '../../../../../../services/sentryService';
import usePartnerOrganizationsAPI from '../../../../../../hooks/usePartnerOrganizationsAPI';
import RestoreOrganizationModal, {
  IRestoreOrganizationModalSubmitResult,
} from '../RestoreOrganizationModal';
import {
  REACT_QUERY_ORGANIZATION_SETTINGS_INFO_KEY, REACT_QUERY_ORGANIZATION_KEY,
} from '../../../../../../hooks/reactQuery';
import {
  REACT_QUERY_ORGANIZATIONS_EXTENDED_KEY,
} from '../../../../../../hooks/tables/useOrganizationsExtendedTable';

interface IRestoreOrganizationWidgetProps {
  organizationId: string;
  organizationName: string;
  organizationPartnerId: string;
  isOrganizationLoading: boolean;
  deletedAt: string;
  hardDeletionAt: string;
}

const RestoreOrganizationWidget = (props: IRestoreOrganizationWidgetProps) => {
  const {
    organizationId,
    organizationName,
    organizationPartnerId,
    isOrganizationLoading,
    deletedAt,
    hardDeletionAt,
  } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();
  const { userCan, currentUser } = useUser();
  const { restoreOrganization } = usePartnerOrganizationsAPI();
  const queryClient = useQueryClient();

  const { days: period } = useMemo(
    () => getDatesDiff({ from: new Date(deletedAt), to: new Date(hardDeletionAt) }),
    [deletedAt, hardDeletionAt],
  );

  const [isRestoreOrganizationModalOpen, setIsRestoreOrganizationModalOpen] = useState(false);
  const [isRestoreOrganizationLoading, setIsRestoreOrganizationLoading] = useState(false);

  const handleRestoreOrganizationModalOpen = useCallback(
    () => setIsRestoreOrganizationModalOpen(true),
    [],
  );

  const handleRestoreOrganizationModalClose = useCallback(
    () => setIsRestoreOrganizationModalOpen(false),
    [],
  );

  const handleRestoreOrganization = useCallback(async (
    params: IRestoreOrganizationModalSubmitResult,
  ) => {
    const { employeeAccountIds } = params;
    try {
      setIsRestoreOrganizationLoading(true);
      await restoreOrganization({ organizationId, employeeAccountIds });
      showSuccess({ text: t('organization.settings.restore.form.success.title') });
      resetReactQuery(queryClient, REACT_QUERY_ORGANIZATION_KEY);
      resetReactQuery(queryClient, REACT_QUERY_ORGANIZATION_SETTINGS_INFO_KEY);
      resetReactQuery(queryClient, REACT_QUERY_ORGANIZATIONS_EXTENDED_KEY);
      handleRestoreOrganizationModalClose();
    } catch (e: any) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, { message: 'RestoreOrganizationWidget/handleRestoreOrganization exception' });
    } finally {
      setIsRestoreOrganizationLoading(false);
    }
  }, [
    restoreOrganization,
    organizationId,
    showSuccess,
    t,
    queryClient,
    handleRestoreOrganizationModalClose,
    showError,
  ]);

  const { partnerId: currentUserPartnerId, partnerType } = currentUser ?? {};

  const { canRestoreOrganization, restoreOrganizationButtonTooltip } = useMemo(() => {
    if (!userCan(ACLEnum.OrganizationRestore)) {
      return {
        canRestoreOrganization: false,
        restoreOrganizationButtonTooltip: t(
          'organization.settings.restore.button.tooltip.onlyAdmin',
        ),
      };
    }
    if (isUniqkey(partnerType)) { // Uniqkey partner can restore organizations
      return {
        canRestoreOrganization: true,
        restoreOrganizationButtonTooltip: null,
      };
    }
    if (currentUserPartnerId === organizationPartnerId) {
      /*
        can restore an organization if the current user's partner is the same
        as the organization's partner
      */
      return {
        canRestoreOrganization: true,
        restoreOrganizationButtonTooltip: null,
      };
    }
    return {
      canRestoreOrganization: false,
      restoreOrganizationButtonTooltip: t(
        'organization.settings.restore.button.tooltip.onlyParent',
      ),
    };
  }, [userCan, partnerType, currentUserPartnerId, organizationPartnerId, t]);

  const processedDeletedAt = useMemo(() => formatDate({ date: deletedAt }), [deletedAt]);

  const processedHardDeletionAt = useMemo(
    () => getRemainingTimeTo({ date: hardDeletionAt, t, showTill: TimeSpanEnum.Days }),
    [hardDeletionAt, t],
  );

  return (
    <WidgetContainer
      container
      flexDirection="column"
      py={3}
      withShadow
      isLoading={isOrganizationLoading}
      rowGap={4}
      flexWrap="nowrap"
    >
      <Grid container flexDirection="column" rowGap={2}>
        <Typography variant="subtitle1" color={S6}>
          {t('organization.settings.restore.title')}
        </Typography>
        <Typography variant="caption" color={S5}>
          {t('organization.settings.restore.description.items.first')}
          &nbsp;
          <Typography variant="caption" color={AO4}>
            {processedDeletedAt}
          </Typography>
          &nbsp;
          {t('organization.settings.restore.description.items.second')}
          &nbsp;
          <Typography variant="caption" color={AO4}>
            {t(
              'organization.settings.restore.description.items.third',
              { period: Math.floor(period) },
            )}
          </Typography>
          {t('organization.settings.restore.description.items.fourth')}
        </Typography>
      </Grid>
      <Grid container flexWrap="nowrap">
        <Grid container item xs flexDirection="column">
          <Typography variant="caption" color={S5} whiteSpace="nowrap">
            {t('organization.settings.restore.retentionPeriod.title')}
          </Typography>
          <Typography color={AR2} whiteSpace="nowrap">
            {processedHardDeletionAt ?? t('common.time.left.days', { days: 1 })}
          </Typography>
        </Grid>
        <Grid container item justifyContent="flex-end">
          <Tooltip title={restoreOrganizationButtonTooltip} followCursor>
            <Button
              fullWidth
              disabled={!canRestoreOrganization}
              onClick={handleRestoreOrganizationModalOpen}
            >
              {t('organization.settings.restore.title')}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {isRestoreOrganizationModalOpen && (
        <RestoreOrganizationModal
          organizationName={organizationName}
          organizationId={organizationId}
          isOpen={isRestoreOrganizationModalOpen}
          isRestoreOrganizationLoading={isRestoreOrganizationLoading}
          onSubmit={handleRestoreOrganization}
          onClose={handleRestoreOrganizationModalClose}
        />
      )}
    </WidgetContainer>
  );
};

export default memo(RestoreOrganizationWidget);
