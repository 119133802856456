import {
  WidgetContainer,
  Grid,
  Typography,
  S6,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { memo } from 'react';
import { GetOrganizationSettingsInfoResponse } from '@uniqkey-backend-partner/api-client';
import MoveDetailsInfoWidgetItem from './components/MoveDetailsInfoWidgetItem';

interface IMoveDetailsInfoWidgetProps extends Pick<GetOrganizationSettingsInfoResponse,
  'movedFromPartnerName' | 'movedFromContactPersonName'
  | 'movedFromContactPersonEmail' | 'movedFromContactPersonPhone'> {}

const MoveDetailsInfoWidget = (props: IMoveDetailsInfoWidgetProps) => {
  const {
    movedFromPartnerName,
    movedFromContactPersonName,
    movedFromContactPersonEmail,
    movedFromContactPersonPhone,
  } = props;
  const { t } = useTranslations();

  return (
    <WidgetContainer
      container
      flexDirection="column"
      py={3}
      withShadow
      rowGap={2}
      flexWrap="nowrap"
    >
      <Grid container>
        <Typography variant="subtitle1" color={S6}>
          {t('moveDetailsInfoWidget.title')}
        </Typography>
      </Grid>
      <Grid container columnGap={1} flexWrap="nowrap">
        <Grid container item xs={6} flexDirection="column" rowGap={2}>
          <MoveDetailsInfoWidgetItem
            title={t('moveDetailsInfoWidget.partnerName')}
            value={movedFromPartnerName}
          />
          <MoveDetailsInfoWidgetItem
            title={t('moveDetailsInfoWidget.contactName')}
            value={movedFromContactPersonName}
          />
        </Grid>
        <Grid container item xs={6} flexDirection="column" rowGap={2}>
          <MoveDetailsInfoWidgetItem
            title={t('moveDetailsInfoWidget.contactEmail')}
            value={movedFromContactPersonEmail}
          />
          <MoveDetailsInfoWidgetItem
            title={t('moveDetailsInfoWidget.contactPhone')}
            value={movedFromContactPersonPhone}
          />
        </Grid>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(MoveDetailsInfoWidget);
