enum ReactQueryKeyEnum {
  SupportedLanguages = 'supportedLanguages',
  Partners = 'partners',
  Partner = 'partner',
  PartnerMoveInfo = 'partnerMoveInfo',
  PartnerUsers = 'partnerUsers',
  PartnerUsersForAdmin = 'partnerUsersForAdmin',
  PartnerUser = 'partnerUser',
  PartnerLicensesHistory = 'partnerLicensesHistory',
  CurrentUser = 'currentUser',
  Organizations = 'organizations',
  OrganizationsExtended = 'organizationsExtended',
  Organization = 'organization',
  OrganizationSettingsInfo = 'organizationSettingsInfo',
  OrganizationAdmins = 'organizationAdmins',
  OrganizationAuditLogs = 'organizationAuditLogs',
  OrganizationAccessInfo = 'organizationAccessInfo',
  OrganizationEmployees = 'organizationEmployees',
  OrganizationEmployee = 'organizationEmployee',
  OrganizationEmployeeLogins = 'organizationEmployeeLogins',
  OrganizationEmployeeAuditLogs = 'organizationEmployeeAuditLogs',
  OrganizationLicensesHistory = 'organizationLicensesHistory',
  OrganizationVerifiedDomains = 'organizationVerifiedDomains',
  ContactPerson = 'contactPerson',
  Groups = 'groups',
  Group = 'group',
  GroupUsers = 'groupUsers',
  GroupOrganizations = 'groupOrganizations',
  GroupOrganizationsForAdmin = 'groupOrganizationsForAdmin',
  GroupLatestOrganizations = 'groupLatestOrganizations',
  GroupLatestOrganizationsForAdmin = 'groupLatestOrganizationsForAdmin',
  Roles = 'roles',
  Role = 'role',
  ACLs = 'ACLs',
  AuditLogs = 'auditLogs',
}

export default ReactQueryKeyEnum;
