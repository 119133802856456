import {
  WidgetContainer,
  Grid,
  Typography,
  Button,
  useTranslations,
  S5,
  S6,
  useSnackbar,
  Tooltip,
  resetReactQuery,
} from '@uniqkey-frontend/shared-app';
import {
  useCallback, useState, memo, useMemo, useEffect,
} from 'react';
import {
  OrganizationArchivedNotification,
} from '@uniqkey-backend-partner/api-client';
import { useQueryClient } from 'react-query';
import { logException } from '../../../../../../services/sentryService';
import DeleteOrganizationModal, {
  IDeleteOrganizationModalSubmitResult,
} from '../DeleteOrganizationModal';
import {
  REACT_QUERY_ORGANIZATION_KEY,
  REACT_QUERY_ORGANIZATION_SETTINGS_INFO_KEY,
} from '../../../../../../hooks/reactQuery';
import usePartnerOrganizationsAPI from '../../../../../../hooks/usePartnerOrganizationsAPI';
import { useUser } from '../../../../../../contexts/UserContext';
import ACLEnum from '../../../../../../enums/ACLEnum';
import { isUniqkey } from '../../../../../../helpers/partnerType';
import { subscribeToRealtimeAPIEvent } from '../../../../../../services/webSocketsManager';
import RealtimeAPIEventTypeEnum from '../../../../../../enums/RealtimeAPIEventTypeEnum';
import {
  REACT_QUERY_ORGANIZATIONS_EXTENDED_KEY,
} from '../../../../../../hooks/tables/useOrganizationsExtendedTable';

interface IDeleteOrganizationWidgetProps {
  organizationId: string;
  organizationName: string;
  organizationPartnerId: string;
  isOrganizationLoading: boolean;
}

const SUCH_DELETION_REQUEST_CANNOT_BE_FOUND_ERROR = 'Such_DeletionRequest_cannot_be_found';

const DeleteOrganizationWidget = (props: IDeleteOrganizationWidgetProps) => {
  const {
    organizationId, organizationName, organizationPartnerId, isOrganizationLoading,
  } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();
  const { userCan, currentUser } = useUser();
  const { requestOrganizationDeletion, deleteOrganization } = usePartnerOrganizationsAPI();
  const queryClient = useQueryClient();

  const [isDeleteOrganizationModalOpen, setIsDeleteOrganizationModalOpen] = useState(false);
  const [
    isDeleteOrganizationModalOpenLoading,
    setIsDeleteOrganizationModalOpenLoading,
  ] = useState(false);
  const [isDeleteOrganizationLoading, setIsDeleteOrganizationLoading] = useState(false);

  const handleDeleteOrganizationModalOpen = useCallback(async () => {
    try {
      setIsDeleteOrganizationModalOpenLoading(true);
      await requestOrganizationDeletion({ organizationId });
      setIsDeleteOrganizationModalOpen(true);
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'DeleteOrganizationWidget/handleDeleteOrganizationModalOpen exception',
      });
    } finally {
      setIsDeleteOrganizationModalOpenLoading(false);
    }
  }, [organizationId, requestOrganizationDeletion, showError, t]);

  const handleDeleteOrganizationModalClose = useCallback(
    () => setIsDeleteOrganizationModalOpen(false),
    [],
  );

  const handleDeleteOrganization = useCallback(async (
    params: IDeleteOrganizationModalSubmitResult,
  ) => {
    const { code, retentionPeriodInDays } = params;
    try {
      setIsDeleteOrganizationLoading(true);
      await deleteOrganization(code, organizationId, retentionPeriodInDays);
    } catch (e: any) {
      let key = 'common.somethingWentWrong';
      if (e?.response?.data?.includes(SUCH_DELETION_REQUEST_CANNOT_BE_FOUND_ERROR)) {
        key = 'organization.settings.delete.error.suchDeletionRequestCannotBeFound.title';
      }
      showError({ text: t(key) });
      logException(e, { message: 'DeleteOrganizationWidget/handleDeleteOrganization exception' });
      setIsDeleteOrganizationLoading(false);
    }
  }, [deleteOrganization, organizationId, showError, t]);

  useEffect(() => {
    const unsubscribe = subscribeToRealtimeAPIEvent<OrganizationArchivedNotification>(
      RealtimeAPIEventTypeEnum.OrganizationArchivedNotification,
      async (event) => {
        const { isSuccessful } = event;
        setIsDeleteOrganizationLoading(false);
        if (!isSuccessful) {
          showError({ text: t('common.somethingWentWrong') });
          return;
        }
        showSuccess({ text: t('organization.settings.delete.success.title') });
        resetReactQuery(queryClient, REACT_QUERY_ORGANIZATION_KEY);
        resetReactQuery(queryClient, REACT_QUERY_ORGANIZATION_SETTINGS_INFO_KEY);
        resetReactQuery(queryClient, REACT_QUERY_ORGANIZATIONS_EXTENDED_KEY);
        setIsDeleteOrganizationModalOpen(false);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [queryClient, showError, showSuccess, t]);

  const { partnerId: currentUserPartnerId, partnerType } = currentUser ?? {};

  const { canDeleteOrganization, deleteOrganizationButtonTooltip } = useMemo(() => {
    if (!userCan(ACLEnum.OrganizationDelete)) {
      return {
        canDeleteOrganization: false,
        deleteOrganizationButtonTooltip: t('organization.settings.delete.button.tooltip.onlyAdmin'),
      };
    }
    if (isUniqkey(partnerType)) { // Uniqkey partner can delete organizations
      return {
        canDeleteOrganization: true,
        deleteOrganizationButtonTooltip: null,
      };
    }
    if (currentUserPartnerId === organizationPartnerId) {
      /*
        can delete an organization if the current user's partner is the same
        as the organization's partner
      */
      return {
        canDeleteOrganization: true,
        deleteOrganizationButtonTooltip: null,
      };
    }
    return {
      canDeleteOrganization: false,
      deleteOrganizationButtonTooltip: t('organization.settings.delete.button.tooltip.onlyParent'),
    };
  }, [userCan, partnerType, currentUserPartnerId, organizationPartnerId, t]);

  return (
    <WidgetContainer
      container
      flexDirection="column"
      py={3}
      withShadow
      isLoading={isOrganizationLoading}
      rowGap={4}
      flexWrap="nowrap"
    >
      <Grid container flexDirection="column" rowGap={2}>
        <Typography variant="subtitle1" color={S6}>
          {t('organization.settings.delete.title')}
        </Typography>
        <Typography variant="caption" color={S5}>
          {t('organization.settings.delete.description')}
        </Typography>
      </Grid>
      <Tooltip title={deleteOrganizationButtonTooltip} followCursor>
        <Grid container item xs={6}>
          <Button
            color="error"
            fullWidth
            isLoading={isDeleteOrganizationModalOpenLoading}
            disabled={!canDeleteOrganization}
            onClick={handleDeleteOrganizationModalOpen}
          >
            {t('organization.settings.delete.title')}
          </Button>
        </Grid>
      </Tooltip>
      {isDeleteOrganizationModalOpen && (
        <DeleteOrganizationModal
          organizationName={organizationName}
          organizationId={organizationId}
          isOpen={isDeleteOrganizationModalOpen}
          isDeleteOrganizationLoading={isDeleteOrganizationLoading}
          onSubmit={handleDeleteOrganization}
          onClose={handleDeleteOrganizationModalClose}
        />
      )}
    </WidgetContainer>
  );
};

export default memo(DeleteOrganizationWidget);
