import {
  useCallback, memo, useMemo, SyntheticEvent,
} from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations, RenderIf,
} from '@uniqkey-frontend/shared-app';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import { useGetPartnerById, useGetPartnerUserById } from '../../../../hooks/reactQuery';
import PartnerUserDetailsTab from '../../../PartnerUserPage/components/PartnerUserDetailsTab';
import { parseIdParam } from '../../../../helpers/parseIdParam';

export enum PartnerPartnerUserModuleTabEnum {
  Details = 'details',
}
interface IPartnerPartnerUserModuleProps {
  activeTab: PartnerPartnerUserModuleTabEnum;
}
interface IPartnerPartnerUserModuleContext {
  partnerId1: string;
  partnerId2: string;
  partnerId3: string;
  partnerUserId: string;
}

const PartnerPartnerUserModule = (props: IPartnerPartnerUserModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const context = useOutletContext<IPartnerPartnerUserModuleContext>();
  const navigate = useNavigate();

  const {
    partnerId1, partnerId2, partnerId3, partnerUserId,
  } = context;

  const { parsedPartnerId1, parsedPartnerId2, parsedPartnerId3 } = useMemo(() => ({
    parsedPartnerId1: parseIdParam(context.partnerId1),
    parsedPartnerId2: parseIdParam(context.partnerId2),
    parsedPartnerId3: parseIdParam(context.partnerId3),
  }), [context]);

  const { isLoading: isLoading1 } = useGetPartnerById(
    { partnerId: partnerId1 },
    { enabled: !!parsedPartnerId1 },
  );

  const { isLoading: isLoading2 } = useGetPartnerById(
    { partnerId: partnerId2 },
    { enabled: !!parsedPartnerId2 },
  );

  const { isLoading: isLoading3 } = useGetPartnerById(
    { partnerId: partnerId3 },
    { enabled: !!parsedPartnerId3 },
  );

  const { isLoading: isPartnerUserLoading } = useGetPartnerUserById(
    { partnerUserId },
  );

  const isLoading = isLoading1 || isLoading2 || isLoading3 || isPartnerUserLoading;

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: PartnerPartnerUserModuleTabEnum,
  ) => {
    const nextPath = `${
      PageRouteEnum.User
    }/${
      partnerId1
    }/${partnerId2 ?? null}/${partnerId3 ?? null}/${partnerUserId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [partnerId1, partnerId2, partnerId3, partnerUserId, activeTab, navigate]);

  const hidePanelHeaderBottomDivider = activeTab === PartnerPartnerUserModuleTabEnum.Details;

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('partnerPartnerUserPage.tabs.userDetails')}
            value={PartnerPartnerUserModuleTabEnum.Details}
            onClick={handleTabClick}
          />
        </Tabs>
      </PanelHeader>
      <RenderIf condition={activeTab === PartnerPartnerUserModuleTabEnum.Details}>
        <PartnerUserDetailsTab partnerUserId={partnerUserId} />
      </RenderIf>
    </Panel>
  );
};

export default memo(PartnerPartnerUserModule);
